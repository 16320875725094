//   ____           _  __    
//  / __"| u       |"|/ /    
// <\___ \/        | ' /     
//  u___) |      U/| . \\u   
//  |____/>>       |_|\_\    
//   )(  (__)    ,-,>> \\,-. 
//  (__)          \.)   (_/  
// 

import {
    scrollTo
} from 'scroll-js';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import {
    saveAs
} from 'file-saver';

let vw,
    vh,
    tw,
    ts,
    ws,
    is,
    tf,
    tx,
    nts,
    activeinput,
    signalinput = [],
    speed = 0.008,
    wheelreturn,
    pause = false;

let transmitting = {};
transmitting.post = false;
transmitting.changed = true;


const text = document.getElementById('proposition-text');

// const signalurl = 'http://localhost:8888/21_neurostock/03_server_structure/';
const signalurl = 'https://neurovague.net/eye/';


const objlist = {
    options: 9,
    txt: ["one", "two", "three", "four", "five", "ten", "twenty", "thirty", "302"],
    title: "Number of Objects",
    txtvalue: false
};

const unilist = {
    options: 4,
    txt: ["appear dissimilar",
        "seem unrelated",
        "are potentially alike",
        "are apparently identical"
    ],
    title: "Uniformity",
    txtvalue: false
};

const conlist = {
    options: 3,
    txt: ["typically isolated",
        "somewhat connected",
        "rampantly interconnected"
    ],
    title: "Connectivity",
    txtvalue: false
};

let signaldata = [];

function importAll(r) {
    return r.keys().map(r);
}

function getJSON(path) {
    return fetch(path).then(response => response.json());
}

function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}


init();

function init() {

    resize();
    window.removeEventListener('resize', resize, false);
    window.addEventListener('resize', resize);

    let activepage = document.querySelector('.page-active');

    if (activepage !== null) {

        if (activepage.getAttribute('id') === 'landing') {

            inittext();

        }

    }

    inittitle();
    initchapters();

    buildselection();
    buildui();

}

function resize() {

    vw = window.innerWidth;
    vh = window.innerHeight;

    let textwrap = document.getElementById('proposition');

    if (text !== null) {

        tw = textwrap.getBoundingClientRect().width / text.getBoundingClientRect().width * 100;

    }

}

function cleanup() {

    // WHEEL

    document.removeEventListener('wheel', processwheel);
    document.removeEventListener('touchstart', processtouch);
    document.removeEventListener('touchend', endtouch);

    pause = true;

}

function initchapters() {

    let routes = document.querySelectorAll('.title-routing');

    if (routes.length) {

        for (let i = 0; i < routes.length; i++) {

            routes[i].addEventListener("click", (e) => {

                e.preventDefault();

                document.body.classList.add('flow');

                let href = routes[i].dataset.url;
                history.pushState(null, '', href);

                let id = routes[i].dataset.id;

                setTimeout(function() {

                    let active = document.querySelectorAll('.page-active');

                    for (let p = 0; p < active.length; p++) {

                        active[p].classList.remove('page-active');

                    }

                    let content = document.getElementById(id);
                    content.classList.add('page-active');

                    document.body.classList.remove('flow');

                    if (id === 'landing') {

                        pause = false;

                        resize();
                        inittext();

                    } else {

                        cleanup();

                    }


                }, 900);


            });

        }

    }

}

function inittitle() {

    let titles = document.querySelectorAll('.title-redraw');

    if (titles.length) {

        for (let i = 0; i < titles.length; i++) {

            let groups = titles[i].querySelectorAll('.title-group');

            for (let g = 0; g < groups.length; g++) {

                settitle(groups[g]);

            }

            titles[i].addEventListener('mouseenter', redrawtitle);

        }


    }

}

function redrawtitle(e) {

    let src = e.srcElement;
    let groups = src.querySelectorAll('.title-group');

    for (let g = 0; g < groups.length; g++) {

        settitle(groups[g]);

    }

}

function settitle(group) {

    let glyphs = group.querySelectorAll('svg');

    let smin = 75;
    let smax = 115;

    for (let i = 0; i < glyphs.length; i++) {

        let ts = random(smin, smax) / 100;

        glyphs[i].style.height = ts + "em";

    }

    setTimeout(function() {

        group.classList.add('glyphs-smooth');

    }, 100);

}

function inittext() {


    ts = 0.9 * tw;
    ws = 0;
    is = 0;
    tf = 0;

    initwheel();


    window.requestAnimationFrame(textstep);


}

function textstep() {

    if (!pause) {

        ts -= speed - ws - is;
        text.style.transform = 'translateX(' + ts + '%)';
        window.requestAnimationFrame(textstep);

        if (ts > tw) {

            ts = -100;

        } else if (ts < -100) {

            ts = tw;

        }

    }

}

function initwheel() {

    document.addEventListener('wheel', processwheel);
    document.addEventListener('touchstart', processtouch);
    document.addEventListener('touchend', endtouch);

}

function processwheel(e) {

    ws = (Math.abs(e.deltaX) > Math.abs(e.deltaY) ? -e.deltaX : e.deltaY) / 1000;
    is = 0;

    clearInterval(wheelreturn);
    wheelreturn = setTimeout(function() {

        is = ws;
        ws = 0;

        idlewheel();

    }, 300);

}

function idlewheel() {

    is *= 0.9;

    if (Math.abs(is) > Math.abs(speed)) {

        window.requestAnimationFrame(idlewheel);

    } else {

        is = 0;

    }

}

function processtouch(e) {

    pause = true;
    tx = e.touches[0].clientX;

    document.addEventListener('touchmove', gettouchforce);

}

function gettouchforce(e) {

    let mx = tx - e.changedTouches[0].clientX;
    let ms = mx / vw * tw;

    nts = ts - ms;

    text.style.transform = 'translateX(' + nts + '%)';

}

function endtouch(e) {

    let ex = e.changedTouches[0].clientX - tx;

    if (ex > 5 || ex < -5) {

        tx = 0;

        is = ex / 800;

        ts = nts;
        nts = 0;

        pause = false;
        window.requestAnimationFrame(textstep);

        idlewheel();

    } else {

        pause = false;
        window.requestAnimationFrame(textstep);

    }

}



function buildui() {

    let parent = document.getElementById('ui');

    if (parent !== null) {

        let wrap = document.createElement('div');
        wrap.classList.add('buttonwrap');

        let add = document.createElement('div');
        add.classList.add('addimage');
        add.innerHTML = 'Add image';
        wrap.appendChild(add);

        add.addEventListener('click', addimage);

        let button = document.createElement('div');
        button.classList.add('getimage');
        button.innerHTML = 'Download';
        wrap.appendChild(button);

        button.addEventListener('click', builddata);

        parent.appendChild(wrap);

    }

}

function buildselection() {

    let parent = document.getElementById('selections');

    if (parent !== null) {

        // BUILDUP

        let selections = {};
        selections.ui = {};
        selections.data = [0, 0, 0];

        let wrap = document.createElement('div');
        wrap.classList.add('wrap');
        selections.ui.wrap = wrap;

        // ADD SWITCH

        if (signaldata.length > 0) {

            let divider = document.createElement('div');
            divider.classList.add('and-divider');
            divider.innerHTML = 'and';
            wrap.appendChild(divider);

        }

        if (signaldata.length > 0) {

            let intro = document.createElement('span');
            intro.classList.add('intro');
            intro.innerHTML = 'an image containing ';
            wrap.appendChild(intro);

        } else {

            let intro = document.createElement('span');
            intro.classList.add('intro');
            intro.innerHTML = 'An image containing ';
            wrap.appendChild(intro);

        }

        // OBJECTS

        let inputobj = buildinput(objlist, false);
        wrap.appendChild(inputobj);
        selections.ui.inputobj = inputobj;
        inputobj.dataset.parameter = "objects";
        inputobj.addEventListener('click', buildoverlay);

        // OBJECT/OBJECTS SWITCH

        let objectswitch = document.createElement('span');
        objectswitch.innerHTML = ' object ';
        wrap.appendChild(objectswitch);
        selections.ui.objectswitch = objectswitch;

        // THAT SWITCH

        let thatswitch = document.createElement('span');
        thatswitch.innerHTML = ' that ';
        thatswitch.classList.add('txt-hide');
        wrap.appendChild(thatswitch);
        selections.ui.thatswitch = thatswitch;

        // UNIFORMITY

        let inputuni = buildinput(unilist, true);
        wrap.appendChild(inputuni);
        selections.ui.inputuni = inputuni;
        inputuni.dataset.parameter = "uniformity";
        inputuni.addEventListener('click', buildoverlay);

        // AND SWITCH

        let andswitch = document.createElement('span');
        andswitch.innerHTML = ' and ';
        andswitch.classList.add('txt-hide');
        wrap.appendChild(andswitch);
        selections.ui.andswitch = andswitch;

        // CONNECIVITY

        let inputcon = buildinput(conlist, true)
        wrap.appendChild(inputcon);
        selections.ui.inputcon = inputcon;
        inputcon.dataset.parameter = "connectivity";
        inputcon.addEventListener('click', buildoverlay);

        // BUILDDOWN

        parent.appendChild(wrap);

        signaldata.push(selections);
        datachange();

    }

}

function addimage() {

    if (signaldata.length < 10) {

        buildselection();

        let bg = document.getElementById('images');
        let dh = bg.scrollHeight;

        if (dh > vh) {

            scrollTo(bg, {
                top: dh - vh,
                duration: 500,
                easing: 'ease-out'
            }).then(function() {

            });

        }

    }

    if (signaldata.length == 10) {

        let parent = document.getElementById('ui');
        let add = parent.querySelector('.addimage');

        if (add != null) {

            add.classList.add('full');

        }

    }

}

function buildinput(list, hide, option) {

    let select = document.createElement('span');
    select.classList.add('select');

    let options = list.txt.length - 1;

    let choise = random(0, options);

    select.dataset.selected = String(choise);

    select.innerHTML = list.txt[choise];

    if (hide) {

        select.classList.add('txt-hide');

    }

    return select

}

function buildoverlay(e) {

    let bg = document.getElementById('images');

    activeinput = e.srcElement;

    let parameter = e.srcElement.dataset.parameter;
    let list;

    if (parameter === "objects") {

        list = objlist.txt;

    } else if (parameter === 'uniformity') {

        list = unilist.txt;

    } else if (parameter === 'connectivity') {

        list = conlist.txt;

    }

    let parent = document.createElement('div');
    parent.classList.add('overlay');

    let wrap = document.createElement('div');
    wrap.classList.add('option-wrap');
    parent.appendChild(wrap);

    for (let i = 0; i < list.length; i++) {

        let option = document.createElement('div');
        option.classList.add('option');
        option.dataset.selected = String(i);
        option.dataset.parameter = parameter;
        option.innerHTML = list[i];

        option.addEventListener('click', applyselection);

        wrap.appendChild(option);

    }

    if (signaldata.length > 1) {

        let remove = document.createElement('div');
        remove.classList.add('option');
        remove.classList.add('remove');
        remove.innerHTML = 'Remove image';
        remove.addEventListener('click', removeimage);
        parent.appendChild(remove);

    }

    bg.classList.add('discreet');
    document.body.appendChild(parent);

    setTimeout(function() {

        parent.classList.add('overlay-active');

    }, 10);

    parent.addEventListener('click', destroyoverlay);

}

function destroyoverlay(e) {

    let bg = document.getElementById('images');
    bg.classList.remove('discreet');

    let parents = document.querySelectorAll('.overlay');

    for (let i = 0; i < parents.length; i++) {

        parents[i].classList.remove('overlay-active');

        setTimeout(function() {

            document.body.removeChild(parents[i]);
            parents[i].innerHTML = '';

        }, 800)

    }

}

function applyselection(e) {

    let selected = e.srcElement.dataset.selected;

    let parameter = e.srcElement.dataset.parameter;
    let list;

    if (parameter === "objects") {

        list = objlist.txt;

    } else if (parameter === 'uniformity') {

        list = unilist.txt;

    } else if (parameter === 'connectivity') {

        list = conlist.txt;

    }

    activeinput.dataset.selected = selected;
    activeinput.innerHTML = list[selected];
    activeinput = null;

    datachange();

}

function removeimage(e) {

    let selections = document.getElementById('selections');

    let parent = activeinput.parentElement;
    activeinput = null;

    let index = signaldata.findIndex(el => el.ui.wrap === parent)
    signaldata.splice(index, 1);

    selections.removeChild(parent);

    if (index === 0){

        let intro = selections.querySelector('.intro');

        if (intro != null){

            intro.innerHTML = 'An image containing ';

        }

        let divider = selections.querySelector('.and-divider');

        if (divider != null){

            let dparent = divider.parentElement;
            dparent.removeChild(divider);

        }

    }

    let bg = document.getElementById('images');

    scrollTo(bg, {
        top: 0,
        duration: 600,
        easing: 'ease-out'
    }).then(function() {

    });

    if (signaldata.length < 10) {

        let ui = document.getElementById('ui');
        let add = ui.querySelector('.addimage');

        if (add != null) {

            add.classList.remove('full');

        }

    }

    datachange();

}

function datachange() {

    transmitting.changed = true;

    for (let i = 0; i < signaldata.length; i++) {

        // UPDATE DATA
        signaldata[i].data[0] = parseInt(signaldata[i].ui.inputobj.dataset.selected);
        signaldata[i].data[1] = parseInt(signaldata[i].ui.inputuni.dataset.selected);
        signaldata[i].data[2] = parseInt(signaldata[i].ui.inputcon.dataset.selected);

        // UPDATE OBJECT SWITCH

        signaldata[i].ui.objectswitch.innerHTML = (signaldata[i].data[0] === 0) ? ' object ' : ' objects ';

        if (signaldata[i].data[0] > 0) {

            signaldata[i].ui.inputuni.classList.remove('txt-hide');
            signaldata[i].ui.inputcon.classList.remove('txt-hide');
            signaldata[i].ui.thatswitch.classList.remove('txt-hide');
            signaldata[i].ui.andswitch.classList.remove('txt-hide');

        } else {

            signaldata[i].ui.inputuni.classList.add('txt-hide');
            signaldata[i].ui.inputcon.classList.add('txt-hide');
            signaldata[i].ui.thatswitch.classList.add('txt-hide');
            signaldata[i].ui.andswitch.classList.add('txt-hide');

        }

    }

}

function builddata() {

    if (!transmitting.post && transmitting.changed) {

        transmitting.post = true;
        watchtransmit(true);

        signal();

    }

}

async function signal() {

    const response = await fetch(
        signalurl, {
            method: 'POST',
            body: JSON.stringify(signaldata)
        }
    );
    const data = await response.json();

    if (data.eye.length !== 0) {

        savezip(data.eye);

    }

}

function loadzip(url) {
    return new Promise(function(resolve, reject) {
        JSZipUtils.getBinaryContent(url, function(err, data) {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
}

function savezip(eye) {

    let zip = new JSZip();

    zip.file('INFO.txt', loadzip('https://neurovague.net/package.json'), {
        binary: true
    });

    for (let i = 0; i < eye.length; i++) {

        zip.file(eye[i].name, loadzip(signalurl + eye[i].src), {
            binary: true
        });

    }

    zip.generateAsync({
            type: "blob"
        })
        .then(function(blob) {

            saveAs(blob, "NEUROVAGUE.zip");

            watchtransmit(false);

            synapse(eye);

        });

}

async function synapse(eye) {

    let synapseurl = signalurl + 'synapse.php';

    const response = await fetch(
        synapseurl, {
            method: 'POST',
            body: JSON.stringify(eye)
        }
    );
    const data = await response.json();

    if (data.eye.length !== 0) {

        transmitting.post = false;
        transmitting.changed = false;

    }

}

function watchtransmit(start) {

    let ui = document.getElementById('ui');
    let download = ui.querySelector('.getimage');

    if (start) {

        if (download != null) {

            download.classList.add('transmitting');

        }

        document.body.classList.add('transmitting');


    } else {

        if (download != null) {

            download.classList.remove('transmitting');

        }

        document.body.classList.remove('transmitting');


    }

}